import React, { useContext } from "react";
import "./About.css";
import { AiFillHtml5 } from "react-icons/ai";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoNodejs } from "react-icons/io";
import { SiJavascript } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { BsFillTerminalFill } from "react-icons/bs";
import { DiGit } from "react-icons/di";
import { FaReact } from "react-icons/fa";
import figmaLogo from "../../images/figma-logo.png";
import { LanguageContext } from "../../../context/LanguageContext";

const About = () => {
  const { language } = useContext(LanguageContext);

  return (
    <section id="about" className="section-title">
      <h1 className="section-title">
        {language === "DE" ? "Über mich" : "About"}
      </h1>

      {language === "EN" ? (
        <p className="about-me-text">
          I'm a Full-stack Developer with interest in making useful projects,
          which can make our lives easier. I enjoy bringing ideas into reality,
          and I am up for any challenge. So what are we building?!
        </p>
      ) : (
        <p className="about-me-text">
          Ich bin Fullstack-Entwickler <br />
          mit Interesse an nützlichen Projekten, die unser Leben leichter
          machen. Ich genieße es, Ideen in die Realität umzusetzen und bin für
          jede Herausforderung bereit. Also was bauen wir?!
        </p>
      )}
      <h4>{language === "DE" ? "Kenntnisse" : "Skills"}</h4>
      <div className="skills-container">
        <div className="skill-card">
          <h5 className="skill-card-title">Tools</h5>
          <div className="row">
            <div className="skill-card-icon">
              <img src={figmaLogo} style={{ width: "15px" }} />
              <h5>Figma</h5>
            </div>
          </div>
          <div className="row">
            <div className="skill-card-icon">
              <DiGit style={{ fontSize: "40px", color: "brown" }} />
              <h5>Git</h5>
            </div>

            <div className="skill-card-icon">
              <BsFillTerminalFill style={{ fontSize: "40px", color: "gray" }} />
              <h5>Bash</h5>
            </div>
          </div>
        </div>
        <div className="skill-card">
          <h5 className="skill-card-title">Frontend</h5>
          <div className="row">
            <div className="skill-card-icon">
              <AiFillHtml5 style={{ fontSize: "25px", color: "brown" }} />
              <h5>HTML</h5>
            </div>

            <div className="skill-card-icon">
              <AiFillHtml5 style={{ fontSize: "25px", color: "blue" }} />
              <h5>CSS</h5>
            </div>
          </div>
          <div className="row">
            <div className="skill-card-icon">
              <SiJavascript style={{ fontSize: "20px", color: "yellow" }} />
              <h5>Javascript</h5>
            </div>

            <div className="skill-card-icon">
              <FaReact style={{ fontSize: "25px", color: "lightblue" }} />
              <h5>React JS</h5>
            </div>
          </div>
        </div>
        <div className="skill-card">
          <h5 className="skill-card-title">Backend</h5>
          <div className="row">
            <div className="skill-card-icon">
              <IoLogoNodejs style={{ fontSize: "25px", color: "brown" }} />
              <h5>Node JS</h5>
            </div>

            <div className="skill-card-icon">
              <SiMongodb style={{ fontSize: "25px", color: "green" }} />
              <h5>MongoDB</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
