import React, {useContext, } from "react";
import "./Home.css";

import { LanguageContext } from "../../../context/LanguageContext";

const Home = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <section id="home" className="home">
      <p>
        {language === "DE" ? (
          <div>
            Hallo!, <br />
            Ich bin Majd Karoun | <br />
            Webentwickler
          </div>
        ) : (
          <div>
            Hello!, <br />
            I'm Majd Karoun | <br />
            Web Developer
          </div>
        )}
      </p>
      <a href="#about">
      <button className="button know-more-btn">
      {language === "DE" ? (
          "Mehr Wissen"
        ) : (
          "Learn More"
        )}
      </button>
      </a>
      <div className="language-picker">
        <select onChange={(choice) => setLanguage(choice.target.value)}>
          <option value="EN">🌐EN</option>
          <option value="DE">🌐DE</option>
        </select>
      </div>
    </section>
  );
};

export default Home;
