import React, { useContext, useState, useEffect, useRef } from "react";
import "./Nav.css";
import { LanguageContext } from "../../context/LanguageContext";

const Nav = () => {
  const { language } = useContext(LanguageContext);
  const [activeSection, setActiveSection] = useState("");

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px" } // Adjust this value to your liking
    )
  );

  useEffect(() => {
    // Observe all the sections with an `id` attribute
    document.querySelectorAll("section[id]").forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      // Unobserve all the sections when the component unmounts
      observer.current.disconnect();
    };
  }, []);

  return (
    <nav>
      <a href="#home" className={activeSection === "home" ? "active" : ""}>
        Home
      </a>
      <a
        href="#about"
        className={activeSection === "about" ? "active" : ""}
      >
        {language === "DE" ? "Über mich" : "About"}
      </a>
      <a
        href="#portfolio"
        className={activeSection === "portfolio" ? "active" : ""}
      >
        Portfolio
      </a>
      <a
        href="#contact"
        className={activeSection === "contact" ? "active" : ""}
      >
        {language === "DE" ? "Kontakt" : "Contact"}
      </a>
    </nav>
  );
};

export default Nav;
