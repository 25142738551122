import React, {useContext} from 'react';
import ContactForm from '../../contact-form/ContactForm';
import "./Contact.css";
import { LanguageContext } from '../../../context/LanguageContext';
import { BsTwitter } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";



const Contact = () => {
 const {language} = useContext(LanguageContext)


  return (
    <section id='contact' className='contact'>
    
    <h2 className='section-title'>{language === "DE" ? "Kontakt" : "Contact"}</h2>
    <br/>
    <br/>
    <br/>
    <br/>
    <div className="main-container">
    <div className="contact-details">
      <div className='email-details'>
        <h2>E-mail:</h2>
        <h3>majd.karoun@gmail.com</h3>
      </div>
      <div className='phone-details'>
        <h2>{language === "DE" ? "Mobile:" : "Phone:"}</h2>
        <h3>+49 176 80251794</h3>
      </div>
        <div className="social-links">
          
          <a href="https://twitter.com/Majd_Kaorun" target={"_blank"} rel="noreferrer"><BsTwitter/></a>
          <a href="https://www.linkedin.com/in/majd-karoun-3a5209255" target={"_blank"} rel="noreferrer"><BsLinkedin/></a>
          <a href="https://github.com/majd-karoun" target={"_blank"} rel="noreferrer"><BsGithub/></a>
        </div>
      
    </div>
    <ContactForm/>
    </div>

    </section>
  )
}

export default Contact