import React, {useState, useContext} from 'react';
import './ContactForm.css'
import emailjs from "emailjs-com"
import { LanguageContext } from '../../context/LanguageContext';


const ContactForm = () => {
  const {language} = useContext(LanguageContext)
    const [showSentConfirmation, setShowSentConfirmation] = useState(false)


    const sendEmail = (e)=> {
        e.preventDefault()
    
        emailjs.sendForm('service_9he62to','template_tj7vxij',e.target,'OlucHJboJJaCGm7cP')
    
        setShowSentConfirmation(true)
        
    
      }


  return (
    <form className='contact-form' onSubmit={sendEmail}>
      
      <input  type="name" name='name' required placeholder={language === "DE" ? "Ihren Namen" : "Your name"} />
      <input  type="email" name='email' required placeholder={language === "DE" ? "E-mail Addresse" : "E-mail address"}/>
      
      <textarea placeholder={language === "DE" ? "Nachricht..." : "Your message..."} required name="message" id="message"  cols="80" rows="12"></textarea>

      <button className='button' type='submit' >{language === "DE" ? "Senden" : "Send"}</button>
      {showSentConfirmation && <h5 >Your Message was sent successfully!</h5>}
      {showSentConfirmation}


    </form>
  )
}

export default ContactForm