import React, {useContext } from "react";
import "./Portfolio.css";
import mealcards from "../../images/mealcards.jpg";
import summmaryGpt from "../../images/summarygpt-screenshot.jpg";
import devkitstation from "../../images/devkitstation-screenshot.jpg";
import { LanguageContext } from "../../../context/LanguageContext";

const Portfolio = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section id="portfolio" className="portfolio">
      <h2 className="section-title">Portfolio</h2>
      <div className="projects-container">
        <div className="project-card">
          <img src={mealcards} alt="project screenshot" />
          <h5 className="project-card-title">Meal Cards</h5>

          {language === "DE" ? (
            <p>
              Lernen Sie, wie man Gerichte aus verschiedenen Kulturen kocht mit themealdb API.
            </p>
          ) : (
            <p>
              Learn how to cook meals
             
              from around the world using themealdb API.
            </p>
          )}
          <div className="project-card-actions">
            <button className="button">
              {" "}
              <a target="_blank" rel="noreferrer" href="https://mealcards7.web.app/discover">
                Live Demo
              </a>
            </button>
            <button className="button btn-scnd">
              {" "}
              <a
                target="_blank" rel="noreferrer"
                href="https://github.com/majd-karoun/Meal-Cards">
                GitHub
              </a>
            </button>
          </div>
        </div>

        <div className="project-card">
          <img src={summmaryGpt} alt="project screenshot" />
          <h5 className="project-card-title">Summary-GPT</h5>

          {language === "DE" ? (
            <p>
              Fassen Sie mit Ai mithilfe der ChatGPT-API jedes Buch Ihrer Wahl zusammen und speichern Sie es in einer Minute
            </p>
          ) : (
            <p>
              Summarize and save any book of your <br /> choice in a minute 
              with Ai, using  ChatGPT API
            </p>
          )}
          <div className="project-card-actions">
            <button className="button">
              {" "}
              <a target="_blank"  rel="noreferrer" href="https://summary-gpt.netlify.app/">
                Live Demo
              </a>
            </button>
            <button className="button btn-scnd">
              {" "}
              <a target="_blank" rel="noreferrer" href="https://github.com/majd-karoun/summary-gpt-client">
                GitHub
              </a>
            </button>
          </div>
        </div>
        <div className="project-card">
          <img src={devkitstation} alt="project screenshot" />
          <h5 className="project-card-title">DevKit Station</h5>

          {language === "DE" ? (
            <p>
              Finden Sie viele nützliche Tools und Ressourcen für die Web-Entwicklung mit dieser selbst erstellten API. <br />
              (kostenlose Datenbankebene kann eine Minute dauern)
            </p>
          ) : (
            <p>
              Find lots of useful tools and resources for Web-Development with this
              self-made API. <br />
              (free database tier might take a minute <br /> to load )
            </p>
          )}
          <div className="project-card-actions">
            <button className="button">
              {" "}
              <a target="_blank"  rel="noreferrer" href="https://devkitstation.netlify.app/">
                Live Demo
              </a>
            </button>
            <button className="button btn-scnd">
              {" "}
              <a target="_blank" rel="noreferrer" href="https://github.com/majd-karoun/devkit-station">
                GitHub
              </a>
            </button>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Portfolio;
